/**
 * Theme: Attex - Responsive Tailwind Admin Dashboard
 * Author: coderthemes
 * Module/App: App js
 */
import './bootstrap';
// import 'simplebar';
import { Livewire, Alpine } from '../../../vendor/livewire/livewire/dist/livewire.esm';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css'; // optional for styling
import Clipboard from "@ryangjchandler/alpine-clipboard"
import { MapController } from './maps';
// import Chart from 'chart.js/auto';
// import ChartDataLabels from 'chartjs-plugin-datalabels';
// Chart.register(ChartDataLabels);
// window.Chart = Chart;
Alpine.plugin(Clipboard);
// Assign MapController to the global window object
window.MapController = MapController;
window.Alpine = Alpine;

// Import your controllers
import BaseMapController from './maps/BaseMapController.js';
import PilotMapController from './maps/PilotMapController.js';
import SidebarMapController from './maps/SidebarMapController.js';
import DestinationMapController from './maps/DestinationMapController.js';
import BookFlightMapController from './maps/BookFlightMapController.js';

// Attach them to the global window object
window.BaseMapController = BaseMapController;
window.SidebarMapController = SidebarMapController;
window.PilotMapController = PilotMapController;
window.BookFlightMapController = BookFlightMapController;
window.DestinationMapController = DestinationMapController;

document.addEventListener('alpine:init', () => {
    Alpine.magic('popovers', (el) => (message, placement) => {
        let instance = tippy(el, {
            content: message,
            placement: placement || undefined,
            interactive: true,
            allowHTML: true,
            // hideOnClick: el.getAttribute("data-dismissable") ? true : "toggle",
            delay: el.getAttribute('data-delay') || 0,
            animation: el.getAttribute('data-animation') || 'fade',
            theme: el.getAttribute('data-theme') || '',
            trigger: el.getAttribute('data-trigger') || 'click',
        });

        instance.show();
    });
});

function startTime() {
    var offset = 0;
    var today = new Date();
    var h = today.getUTCHours();
    var m = today.getUTCMinutes();
    var s = today.getUTCSeconds();
    h = h + offset;
    if (h > 24) {
        h = h - 24;
    }
    if (h < 0) {
        h = h + 24;
    }
    h = checkTime(h);
    m = checkTime(m);
    s = checkTime(s);
    document.getElementById('zuluClock').innerHTML = h + ':' + m + ':' + s + 'z';
    var t = setTimeout(function() {
        startTime();
    }, 500);
}

function checkTime(i) {
    if (i < 10) {
        i = '0' + i;
    }
    return i;
}

startTime();

Livewire.start()
